import React, {useState, useEffect} from 'react';
import {Card, Collection, Heading, View} from "@aws-amplify/ui-react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTriangleExclamation, faCircleCheck, faCircleXmark} from "@fortawesome/free-solid-svg-icons";

const sites = [
    {
        title: 'MyEdu Family',
        url: 'https://prod.api.myedufamily.it/auth/api/alive',
    },
    {
        title: 'MyEdu Plus',
        url: 'https://prod.api.myeduplus.it/auth/api/alive',
    },
];

function Site({title, url}) {
    const [status, setStatus] = useState(0);

    useEffect(
        () => {
            fetch(url)
                .then(
                    (response) =>
                        setStatus(response.status)
                )
                .catch(
                    () => setStatus(-1)
                );
        },
        [url]
    );

    let color = "orange";
    let content = "Non è possibile raggiungere la risorsa";
    let icon = faTriangleExclamation;

    // eslint-disable-next-line default-case
    switch (true) {
        case status > 0 && status < 300 && status >= 200:
            color = "green";
            content = "OK";
            icon = faCircleCheck;
            break;
        case status > 0:
            color = 'red';
            content = `KO (${status})`;
            icon = faCircleXmark;
    }

    return (
        <Card
            borderRadius="medium"
            maxWidth="20rem"
            variation="outlined"
        >
            <View>
                <Heading
                    paddingBottom={15}
                >
                    {title}
                </Heading>
                <Heading
                    style={{color}}
                >
                    <FontAwesomeIcon icon={icon}/>&nbsp;&nbsp;{content}
                </Heading>
            </View>
        </Card>
    );
}

export default function HomepageSites() {
    return (
        <Collection
            items={sites}
            type="list"
            direction="row"
            gap="20px"
            wrap="nowrap"
        >
            {
                (item, index) => (
                    <Site key={index} {...item} />
                )
            }
        </Collection>
    );
}
