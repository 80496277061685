import '@aws-amplify/ui-react/styles.css';

import HomepageSites from "./components/HomepageSites";
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src="https://public.files.myedu.it/logo-myedu.png" className="App-logo" alt="logo"/>
                <h1>MyEdu - Stato</h1>
                <HomepageSites/>
            </header>
        </div>
    );
}

export default App;
